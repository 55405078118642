import React, { useContext } from "react";
import path from "path";
import { Flex, HStack, Tag, TagLabel } from "@chakra-ui/react";
import GatsbyLink from "gatsby-link";
import { PathContext } from "../utils";

export function HorizontalNavItem({ to, title }) {
  const { basePath } = useContext(PathContext);
  const [docsetPath] = basePath.split(path.sep);
  const isActive = path.join("/", docsetPath) === to;
  return (
    <Flex as={GatsbyLink} to={to} align="center">
      <Tag
        size="lg"
        borderRadius={"full"}
        colorScheme="blue"
        variant={isActive ? "subtle" : "outline"}
      >
        <TagLabel>{title}</TagLabel>
      </Tag>
    </Flex>
  );
}

export default function HorizontalNav({ children }) {
  return (
    <Flex
      overflowY={"scroll"}
      align="flex-start"
      h={12}
      alignItems="flex-end"
      sx={{ width: "calc(100vw - 2rem)" }}
      justifyContent="flex-start"
    >
      <HStack spacing="4">{children}</HStack>
    </Flex>
  );
}
