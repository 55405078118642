import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  VStack,
  Image,
  IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiArrowRight, FiMoon, FiSun } from "react-icons/fi";
import { Link as GatsbyLink } from "gatsby";
import SafepayLogoDark from "../assets/svg/safepay-logo-dark.svg";
import SafepayLogoLight from "../assets/svg/safepay-logo-white.svg";
import { useTagColors } from "../utils";
import HorizontalNav, { HorizontalNavItem } from "./HorizontalNav";

const HEADER_HEIGHT = 104;
const HEADER_BORDER_WIDTH = 1;
export const TOTAL_HEADER_HEIGHT = HEADER_HEIGHT + HEADER_BORDER_WIDTH;

export default function Header({ children, docsets = [] }) {
  const { toggleColorMode, colorMode } = useColorMode();
  const [tagBg, tagTextColor] = useTagColors();
  const LogoIcon = useColorModeValue(SafepayLogoDark, SafepayLogoLight);
  const [menuItems, setMenuItems] = useState(null);

  var fromIndex = docsets.indexOf(
    docsets.find((element) => element.route === "/")
  );

  var toIndex = 0;

  useEffect(() => {
    var element = docsets[fromIndex];
    docsets.splice(fromIndex, 1);
    docsets.splice(toIndex, 0, element);
    setMenuItems(docsets);
  }, []);

  return (
    <Flex
      align="center"
      px="4"
      as="header"
      boxSizing="content-box"
      pos="sticky"
      top="0"
      zIndex="2"
      bg="bg"
      css={{
        height: HEADER_HEIGHT,
        borderBottomWidth: HEADER_BORDER_WIDTH,
      }}
    >
      <VStack flex="1" spacing={1} align="stretch">
        <Flex>
          <HStack spacing="4" d={{ base: "none", md: "flex" }}>
            <Flex
              as={GatsbyLink}
              to="/"
              align="center"
              d={{ base: "none", md: "flex" }}
            >
              <Image
                w={"24"}
                src={LogoIcon}
                alt="Safepay logo"
                aria-label="Safepay logo"
                mt="0.5"
              />
              <Box
                ml="1.5"
                px="1.5"
                fontSize="sm"
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing="widest"
                bg={tagBg}
                color={tagTextColor}
                rounded="sm"
              >
                Docs
              </Box>
            </Flex>
            {children}
          </HStack>
          <HStack d={{ base: "flex", md: "none" }}>
            {children}
            <Image
              w={"24"}
              src={LogoIcon}
              alt="Safepay logo"
              aria-label="Safepay logo"
              mt="0.5"
            />
          </HStack>
          <IconButton
            ml="auto"
            mr="2"
            fontSize="xl"
            variant="ghost"
            onClick={toggleColorMode}
            icon={colorMode === "dark" ? <FiSun /> : <FiMoon />}
          />

          <Button
            colorScheme="blue"
            rightIcon={<FiArrowRight />}
            as="a"
            href="https://getsafepay.com/dashboard/login"
            target="_blank"
            rel="noopener noreferrer"
            d={{ base: "none", lg: "flex" }}
          >
            Launch Safepay
          </Button>
        </Flex>
        <Flex>
          <HorizontalNav>
            {menuItems?.map((item, i) => (
              <HorizontalNavItem key={i} to={item.route} title={item.title} />
            ))}
          </HorizontalNav>
        </Flex>
      </VStack>
    </Flex>
  );
}

Header.propTypes = {
  children: PropTypes.node,
};
